import React, { useCallback, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import axios from "axios"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"

const Press1 = () => {
  const [press, setPress] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [page, setPage] = useState(1)

  const getPressData = useCallback(page => {
    axios(
      process.env.API_ENDPOINT +
        `public/press-blog?page=${page}&per_page=50&order_by=date&is_ascending=${false}`
    )
      .then(res => {
        if (res.status === 200) {
          setTotalItems(res?.data?.data?.total)
          setPress(prev =>
            !prev.length
              ? res?.data?.data?.result
              : [...prev, ...res?.data?.data?.result]
          )
        }
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    getPressData(page)
  }, [page])

  const fetchData = () => {
    setPage(prev => prev + 1)
  }
  return (
    <section id="press-section1" className="position-relative py-5 mt-5">
      <div className="container py-5">
        <Row>
          <Col md="12" className="mb-5 pb-5">
            <h1 className="mt-5">Press</h1>
            <h5>See who’s talking about SuperBetter</h5>
          </Col>
          <InfiniteScroll
            dataLength={press.length || 0} //This is important field to render the next data
            next={() => fetchData()}
            hasMore={totalItems > press.length}
            style={{ overflow: "hidden" }}
            loader={<h4 className="text-center">Loading Posts...</h4>}
            endMessage={
              !!press.length && (
                <p style={{ textAlign: "center", overflow: "hidden" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              )
            }
          >
            {press?.map((pressSection, index) => (
              <Row key={pressSection.id}>
                <Col md="4" sm="12">
                  {((!!index &&
                    moment(press[index - 1].date).format("YYYY") !==
                      moment(pressSection.date).format("YYYY")) ||
                    index === 0) && (
                    <h1 style={{ color: "#E4EDD5" }}>
                      {moment(pressSection.date).format("YYYY")}
                    </h1>
                  )}
                </Col>
                <Col md="8" sm="12">
                  <div className="blog">
                    <span className="publish-date fs-18 fw-600">
                      {moment(pressSection?.date).format("MMMM D, YYYY")}
                    </span>
                    <div
                      className="blog-content"
                      dangerouslySetInnerHTML={{
                        __html: pressSection?.description,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </InfiniteScroll>
        </Row>
      </div>
    </section>
  )
}

export default Press1
