import React from "react"
import Press1 from "../sections/press/press-1"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Press = () => {
  return (
    <Layout>
      <Seo
        title="Press | Media"
        description="Check this space for stories from press, media & others talking about SuperBetter. We’re champions for using game psychology to tackle tough challenges.  "
        keywords="SuperBetter press, SuperBetter media"
      />
      <Press1 />
    </Layout>
  )
}

export default Press
